// extracted by mini-css-extract-plugin
export var background = "service-module--background--N-Gid";
export var btn = "service-module--btn--GpY2o";
export var container = "service-module--container--7i5oG";
export var containercertandsubs = "service-module--containercertandsubs--L+lhP";
export var description = "service-module--description--fWf2z";
export var link = "service-module--link--YWixr";
export var list = "service-module--list--FG-iV";
export var option = "service-module--option--KCKTn";
export var otherservices = "service-module--otherservices--ZHnCl";
export var preview = "service-module--preview--ZRXwz";
export var price = "service-module--price--6ShBt";
export var stock = "service-module--stock--0TqZu";
export var title = "service-module--title--i2VFv";
export var vector = "service-module--vector--VyfhU";