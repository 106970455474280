import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { drycleaningSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Drycleaning() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={drycleaningSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Химчистка</h2>
            <p className={description}>от 500₽</p>
            <StaticImage
              src="../images/services/1.jpg"
              alt="обложка для Химчистка"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={price}
            style={{
              background: "linear-gradient(180deg, #DEA10B 0%, #FFA764 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Дополнительные позиции обсуждаются индивидуально
            </p>
            <p className={list}>
              - Двухместный диван от 1200₽
              <br />
              - Трехместный диван от 1650₽
              <br />
              - Четырехместный диван от 2200₽
              <br />
              - Пятиместный диван от 2750₽
              <br />
              - Кресло от 500₽
              <br />
              - Матрас от 1200₽
              <br />- Ковер и ковровое покрытие от 140₽/кв.м.
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={0} />
        <AnimatePresence>
          {openForm && (
            <Form service="химчистку" closeForm={() => setOpenForm(false)} />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Drycleaning;
