import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { IoChevronForwardCircle } from "react-icons/io5";
import {
  container,
  slider,
  slide,
  service,
  title,
  costservice,
  background,
  navigation,
} from "./otherservice.module.scss";

function Otherservice({ hide }) {
  const sliderRef = useRef();

  useEffect(() => {
    if (hide !== false) {
      const slideHide =
        sliderRef.current.swiper.wrapperEl.querySelectorAll(".swiper-slide");
      slideHide[hide].remove();
      sliderRef.current.swiper.update();
    } else return;
  }, [hide]);

  return (
    <div className={container}>
      <Swiper
        className={slider}
        spaceBetween={10}
        grabCursor={true}
        breakpoints={{
          320: {
            slidesPerView: 2.5,
          },
          600: {
            slidesPerView: 4.5,
          },
          1025: {
            slidesPerView: hide === false ? 8 : 7,
            spaceBetween: hide === false ? 2 : 10,
          },
        }}
        ref={sliderRef}
      >
        <SwiperSlide className={slide}>
          <Link to="/drycleaning" className={service}>
            <StaticImage
              src="../images/services/1.jpg"
              alt="обложка для Химчистка"
              className={background}
            />
            <p className={title}>Химчистка</p>
            <p className={costservice}>от 500₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/expresscleaning" className={service}>
            <StaticImage
              src="../images/services/2.jpg"
              alt="обложка для Экспресс уборка квартиры"
              className={background}
            />
            <p className={title}>Экспресс уборка квартиры</p>
            <p className={costservice}>от 2200₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/cleaningafterrenovation" className={service}>
            <StaticImage
              src="../images/services/3.jpg"
              alt="обложка для Уборка после ремонта"
              className={background}
            />
            <p className={title}>Уборка после ремонта</p>
            <p className={costservice}>от 4900₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/springcleaning" className={service}>
            <StaticImage
              src="../images/services/4.jpg"
              alt="обложка для Генеральная уборка"
              className={background}
            />
            <p className={title}>Генеральная уборка</p>
            <p className={costservice}>от 4500₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/generalcleaningkitchen" className={service}>
            <StaticImage
              src="../images/services/5.jpg"
              alt="обложка для Генеральная уборка кухни"
              className={background}
            />
            <p className={title}>Генеральная уборка кухни</p>
            <p className={costservice}>от 3000₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/dailycleaning" className={service}>
            <StaticImage
              src="../images/services/6.jpg"
              alt="обложка для Повседневная уборка квартиры"
              className={background}
            />
            <p className={title}>Повседневная уборка квартиры</p>
            <p className={costservice}>от 2700₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/windowsandbalconies" className={service}>
            <StaticImage
              src="../images/services/7.jpg"
              alt="обложка для Окна и балконы"
              className={background}
            />
            <p className={title}>Окна и балконы</p>
            <p className={costservice}>от 400₽</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={slide}>
          <Link to="/additionalservices" className={service}>
            <StaticImage
              src="../images/services/8.jpg"
              alt="обложка для Дополнительные услуги"
              className={background}
            />
            <p className={title}>Дополнительные услуги</p>
            <p className={costservice}>от 350₽</p>
          </Link>
        </SwiperSlide>
      </Swiper>
      <button
        className={navigation}
        onClick={() => sliderRef.current.swiper.slideNext()}
      >
        <IoChevronForwardCircle />
      </button>
    </div>
  );
}

export default Otherservice;
